import { render, staticRenderFns } from "./enterAddress.vue?vue&type=template&id=cb47f4be&scoped=true&"
import script from "./enterAddress.vue?vue&type=script&lang=js&"
export * from "./enterAddress.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb47f4be",
  null
  
)

export default component.exports