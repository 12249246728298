<template>
  <el-dialog
    :append-to-body="true"
    :visible.sync="showWindow"
    :title="getTitle"
    >
    <enter-coordinates
      v-if="showWindowMode === positionOnModes.COORDINATES"
      :user-c-ss="this.userCSs"
      @change-coordinates="changeCoordinatesHandler"
      @change-cs="changeCSHandler"
      ></enter-coordinates>
    <enter-address
      v-if="showWindowMode === positionOnModes.ADDRESS"
      :default-value="addressSettings.addressPrefix"
      :address="addressSettings.valueByDefault"
      @change-coordinates="changeCoordinatesHandler"
      @change-cs="changeCSHandler"
    ></enter-address>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showWindow = false">Отмена</el-button>
      <el-button type="primary" @click="apply">Перейти</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import enterAddress from './enterAddress'
  import enterCoordinates from './enterCoordinates'

  const POSITION_ON_MODE = {
    COORDINATES: 'coordinates',
    ADDRESS: 'address',
    NONE: null
  }

  export default {
    name: 'PositionOn',
    components: { enterAddress, enterCoordinates },
    props: {
      userCSs: {
        type: Array,
        default: function () {
          return []
        }
      },
      addressSettings: {
        type: Object,
        default: function () {
          return {
            addressPrefix: '',
            valueByDefault: ''
          }
        }
      }
    },
    computed: {
      getTitle: function () {
        switch (this.showWindowMode) {
          case POSITION_ON_MODE.COORDINATES:
            return 'Введите координаты'
          case POSITION_ON_MODE.ADDRESS:
            return 'Введите адрес'
          default:
            return ''
        }
      }
    },
    data: function () {
      return {
        showWindowMode: POSITION_ON_MODE.NONE,
        showWindow: false,
        coordinates: {
          x: 0,
          y: 0
        },
        cs: null,
        positionOnModes: POSITION_ON_MODE
      }
    },
    watch: {
      showWindow: {
        handler: function (newValue) {
          if (!newValue) {
            this.showWindowMode = POSITION_ON_MODE.NONE
          }
        }
      },
      showWindowMode: {
        handler: function (newValue) {
          if (newValue === POSITION_ON_MODE.NONE) {
            this.showWindow = false
          } else {
            this.showWindow = true
          }
        }
      }
    },
    methods: {
      changeCoordinatesHandler (coordinates) {
        this.coordinates = coordinates
      },
      changeCSHandler (cs) {
        this.cs = cs
      },
      show (type) {
        if (Object.values(POSITION_ON_MODE).includes(type)) {
          this.showWindowMode = type
        }
      },
      apply () {
        this.$emit('position-on', this.coordinates, this.cs)
        this.cs = null
        this.coordinates = { x: null, y: null }
        this.showWindow = false
      }
    }
  }
</script>

<style scoped>

</style>
